<template>
<CRow>
  <CCol col="12" xl="12">
       <CCard class="cards">
      <CCardBody>
        <div class="container flex-col col-md-12">

        <div class="inner-container flex-col">
            <div class="title flex-row center">
                <h2>{{ $t('message.showPurchaseInvoices')}}</h2>
            </div>
            <div>
                <table class="mb-4">
                <thead>
                    <tr class="row-heading1">
                        <th> {{ $t('message.purchaseId')}}   {{ purchaseInvoices.id }}  </th>
                    </tr>
                </thead>
                <tbody class="heading1">
                  <tr  >
                  <th> {{ $t('message.supplierName')}}  {{purchaseInvoices.supplier.name}} </th>
                  </tr>
                  <tr>
                  <th> {{ $t('message.supplierPhone')}}  {{purchaseInvoices.supplier.phone}} </th>
                  </tr>
                </tbody>
                </table>
            </div>
            <div v-if="purchaseInvoices.order.products" class="row">
            <div class="col-md-10 m-auto">
                <table class="table table-bordered">
                    <thead>
                  <tr>
                    <th> {{$t('message.orderId')}}  </th>
                    <th> {{$t('message.productName')}}  </th>
                    <th> {{$t('message.quantity')}}  </th>
                   <th> {{$t('message.price')}} </th>
                  </tr>
                    </thead>
                    <tbody>
                   <tr v-for=" product in purchaseInvoices.order.products" :key="product.id">
                     <td>{{ purchaseInvoices.order.id}}</td>
                      <td v-if="$i18n.locale == 'English'">{{product.product.name}}</td>
                      <td v-if="$i18n.locale == 'العربية'">{{ product.product.name_ar}}</td>
                    <td>{{ product.quantity }}</td>
                    <td>{{ product.price | number}}</td>
                  </tr>
                </tbody>
                <br>
                 <!-- price_paid -->
                    <tfoot class="">
                    <tr class="mt-3">
                        <th>{{ $t('message.pricePaid')}} : </th>
                        <td>{{ purchaseInvoices.price_paid | number }}</td>
                        <th>{{ $t('message.total')}} : </th>
                        <td class="total" v-if="purchaseInvoices.order !=null" > {{purchaseInvoices.order.total | number}} {{ $t('message.sdg')}} </td>
                    </tr>
                </tfoot>
                </table>
            </div>
        </div>

            <CButton color="btn btn-primary" @click="goBack" class="m-auto"> {{ $t('message.back')}}</CButton>
        </div>

    </div>
      </CCardBody>
    </CCard>
  </CCol>
</CRow>
</template>

<script>
export default {
  name: 'ShowSub-Services',
  data () {
    return {
      purchaseInvoices: [],
      products: [],
      order: {}
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.$http
      .get(`${this.$hostUrl}purchase-invoices/${this.$route.params.id}`)
      .then((response) => {
        this.purchaseInvoices = response.data.data.buy_invoice
      })
    this.$http
      .get(`${this.$hostUrl}purchase-invoices/${this.$route.params.id}`)
      .then((response) => {
        this.products = response.data.data.buy_invoice
      })
  },
  methods: {
    goBack () {
      this.$router.push({ path: '/purchase-invoices' })
    }
  }
}
</script>
<style scoped>
 /* general styling */

* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

button {
    color: #fff;
    letter-spacing: .8px;
    text-transform: uppercase;
    border-radius: 16px;
    padding: 5px;
    font-size: 1.2rem;
    background-color: #2a3647;
    width: 12rem;
    height: 3rem;
    border-width: 0px 2px 4px;
    border-style: solid;
    border-color: #2a3647;
    transition: .1s;
}

button:hover {
    background-color: #2a3647;
    border-color: #2a3647;
    cursor: pointer;
}

button:focus {
    outline: 0;
}

button:active {
    transition: .2s;
    border-width: 2px;
}

h2 {
    color: #2a3647;
    font-size: 1.5rem;
}

/* utility */

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.center {
    justify-content: center;
    align-items: center;
}

/* structure */

.container {
    background-color: #fff;
    border: thick dashed #2a3647;
    padding: 2rem;
}

.title {
    text-align: center;
    padding: 1rem 0;
}

.container table {
    width: 100%;
    color: #2a3647;
    font-weight: 300;
}

.row-data td {
    /* width: 25%; */
    text-align: center;
    padding: 1rem;
    color:#2a3647 !important ;
}

tfoot {
    text-align: center;
    margin: 10px !important;
    padding: 10px !important;
}

tfoot tr * {
    padding: 1rem;
}

.row-data {
    border-bottom: .7px dashed #354728;
}

.row-heading th {
    color: #fff;
    /* width: 25%; */
    text-align: center !important;
    padding: 1rem;
    text-transform: uppercase;
    font-size: 0.9rem;
}
.row-heading1 th {
    color: #354728;
    background-color: #fff !important;
    /* width: 25%; */
    text-align: center !important;
    padding: 1rem;
    text-transform: uppercase;
    font-size: 1.5rem;
}

.heading1 th {
    color: #354728;
    background-color: #fff !important;
    /* width: 25%; */
    text-align: center !important;
    padding: 1rem;
    text-transform: uppercase;
    font-size: 1.0rem;
}
.item-total,
.total {
    font-weight: 400;
}

.btn-container {
    padding: 1rem 0 0;
}

/* footer */
</style>
